import { usePrevious } from 'ahooks'
import { GRID_DEFAULT_SETTINGS } from 'constants/defaultSettings'
import { useSettingsStore } from 'hooks/store'
import { isEqual } from 'lodash'
import { setSetting } from 'services/SettingsProvider'
import { useCurrentColState } from './useCurrentColState'
import { useColStateOrdering } from './useColStateOrdering'
import { useEffect } from 'react'

const getSettings = (gridSettings, standalone, standaloneSettings) => {
  if (standalone) return standaloneSettings
  return gridSettings
}

export const useGridSettingsHandler = ({ id, standalone, standaloneSettings, setStandaloneSettings, defaultSort, sortKeys, pageResetFilters }) => {
  // The settings based code is quite ugly. But we can't easily change much without making people use their grid configs.
  // Grid Tool panels are memoized, if we create them with the props on render, the panel would be closed.
  // This requires us to let the tool panel be able to read and save the settings by itself, instead of just passing settings and a setSetting callback as props.
  const defaultSortModel = defaultSort ? defaultSort.map(s => ({ colId: s.key, sort: s.desc ? 'desc' : 'asc' })) : []

  const intSettingsKey = `grid-${id}`
  const stateGridSettings = useSettingsStore(state => {
    return state.settings[intSettingsKey] || (defaultSortModel ? { ...GRID_DEFAULT_SETTINGS, sortModel: defaultSortModel } : GRID_DEFAULT_SETTINGS)
  })

  const settings = getSettings(stateGridSettings, standalone, standaloneSettings)
  const gridSettings = standalone
    ? {
        ...stateGridSettings,
        pagination: settings
      }
    : stateGridSettings

  const colState = useCurrentColState(settings)
  const ordering = defaultSort ? useColStateOrdering(colState, defaultSort, sortKeys) : null

  const set = (s) => {
    if (id) {
      // if an ID is given, it's page independent, currently only used by ServerSideGrid
      setSetting({ [intSettingsKey]: { ...settings, ...s } })
    } else if (standalone) {
      // if standalone settings are given
      setStandaloneSettings({ ...standaloneSettings, ...s })
    } else {
      console.warn('Cannot save settings without an ID or standalone settings')
    }
  }

  const handlePageReset = () => {
    set({ pagination: { current: 1, pageSize: gridSettings?.pagination ? gridSettings.pagination.pageSize : GRID_DEFAULT_SETTINGS.pagination.pageSize } })
  }

  const previousPageResetFilters = usePrevious(pageResetFilters)
  useEffect(() => {
    if (!previousPageResetFilters || Object.keys(previousPageResetFilters).length === 0 || isEqual(pageResetFilters, previousPageResetFilters)) return
    handlePageReset()
  }, [pageResetFilters, previousPageResetFilters])

  return {
    colState,
    ordering,
    settings,
    set,
    handlePageReset,
    gridProps: {
      id,
      defaultSort,
      sortKeys
    }
  }
}
