import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useQueryFetcher, useSelectedCustomer, usePageSettings } from 'hooks'
import queryString from 'query-string'
import { calculateUpperBoundFromQuartiles, getPatchValues, handleMutationError } from 'utils'
import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'ahooks'
import { pick } from 'lodash'
import { Filter } from 'constants/index'
import { addWeeks, parseISO } from 'date-fns'
import { formatISODate } from 'utils/datetime'

/** Notes about action query parameter

group: Filter for the latest records per data/category/location
goals: add goal_normal, goal_good fields
costs: add disposal_cost, cogs_cost, water, co2 fields
weather: add weather_code, weather_description fields
num_sold: add num_sold, grams_per_num_sold fields

then remove all non active records according to waste categories
**/

const upperBoundCache = new Map()
export const useWasteMeasurements = (options) => {
  const opts = {
    open: false,
    enabled: true,
    item: null,
    filters: null,
    ordering: null,
    pagination: null,
    fields: null,
    ...options
  }

  const queryClient = useQueryClient()
  const { fetch, token } = useQueryFetcher()
  const { settings, set } = usePageSettings()
  const customer = useSelectedCustomer()

  const [idCollection, setIdCollection] = useState(null)
  const [idCollectionRealCount, setIdCollectionRealCount] = useState(null)

  const filterParams = useMemo(() => {
    let res
    if (idCollection != null) {
      res = {
        id: idCollection.join(','),
        ...(opts.ordering ? { ordering: opts.ordering } : { ordering: 'id' }),
        page: 1,
        page_size: 999
      }
    } else if (opts.filters) {
      res = {
        ...pick(opts.filters, ['open']),
        ...(opts.filters.sales_locations ? { sales_locations: opts.filters.sales_locations.join(',') } : undefined),
        ...(opts.filters.wasteCategory ? { categories: opts.filters.wasteCategory.join(',') } : undefined),
        ...(opts.filters.range ? { date_range: opts.filters.range.join(',') } : undefined),
        ...(opts.ordering ? { ordering: opts.ordering } : { ordering: 'id' }),
        page: 1,
        page_size: 999
      }
    } else if (opts.item) {
      res = {
        categories: opts.item.category_id,
        sales_locations: opts.item.sales_location_id,
        date_range: `${opts.item.date},${opts.item.date}`,
        ordering: 'id',
        page: 1,
        page_size: 999
      }
    } else {
      res = {
        ...(settings[Filter.DATE_RANGE] ? { date_range: settings[Filter.DATE_RANGE]?.value?.join(',') } : undefined),
        ...(settings.location ? { sales_locations: Array.isArray(settings.location) ? settings.location.join(',') : [settings.location] } : undefined),
        ...(settings.wasteCategory ? { categories: settings.wasteCategory.join(',') } : undefined),
        ...((settings.status != null) ? { open: settings.status === 'open' } : undefined),
        ...(opts.ordering ? { ordering: opts.ordering } : { ordering: 'id' }),
        page: opts.pagination ? opts.pagination.current || 1 : 1,
        page_size: opts.pagination ? opts.pagination.pageSize || 25 : 25
      }
    }
    return res
  }, [settings, idCollection, opts.filters, opts.item, opts.ordering])

  const isEnabled = opts.enabled

  // this is the filter set for when we are in the open view. If one of these filters changes, we need to reset the id collection
  const relFilterSet = useMemo(() => {
    return {
      ...pick(settings, [Filter.DATE_RANGE, 'wasteCategory', Filter.LOCATION]),
      page: opts.pagination ? opts.pagination.current || 1 : 1,
      pageSize: opts.pagination ? opts.pagination.pageSize || 25 : 25,
      ...(opts.ordering ? { ordering: opts.ordering } : { ordering: 'id' })
    }
  }, [settings, opts.ordering])
  const previousSettings = usePrevious(settings)
  const previousOrdering = usePrevious(opts.ordering)
  const previousPagination = usePrevious(opts.pagination)
  const previousRelFilterSet = previousSettings
    ? {
        ...pick(previousSettings, [Filter.DATE_RANGE, 'wasteCategory', Filter.LOCATION]),
        page: previousPagination ? previousPagination.current || 1 : 1,
        pageSize: previousPagination ? previousPagination.pageSize || 25 : 25,
        ...(previousOrdering ? { ordering: previousOrdering } : { ordering: 'id' })
      }
    : null

  useEffect(() => {
    if (idCollection != null && relFilterSet && previousRelFilterSet) {
      const diff = getPatchValues(relFilterSet, previousRelFilterSet, [Filter.DATE_RANGE, 'wasteCategory', Filter.LOCATION, 'page', 'pageSize', 'ordering'])
      if (Object.keys(diff).length > 0) {
        setIdCollection(null)
        setIdCollectionRealCount(null)
      }
    }
  }, [relFilterSet, previousSettings])

  const isInOpenView = useMemo(() => settings.status === 'open', [settings.status])

  const queryKey = ['waste-measurements', customer, opts.item != null, filterParams, opts.fields]
  const baseUrl = '/food-waste/wastes/'

  const { data, isFetching, status } = useQuery({
    queryKey,
    queryFn: () => new Promise((resolve, reject) => {
      fetch(
        `${baseUrl}?${queryString.stringify({
          customer,
          ...(opts.item ? undefined : { actions: 'group,num_sold,costs,weather,goals' }),
          ...filterParams,
          fields: opts.fields === '' ? undefined : opts.fields
        })}`,
        {
          method: 'GET',
          token,
          success: (res) => resolve(res),
          failure: (err) => {
            // on invalid page error (this is normally not possible to achieve using the UI, we go back to page 1)
            if (err.detail === 'Invalid page.') {
              set({ pagination: { ...settings.pagination, current: 1 } })
            }
            reject(err)
          }
        }
      )
    }),
    enabled: isEnabled
  })

  const { mutateAsync: addMutation, isPending: addIsPending } = useMutation({
    mutationFn: (values) => new Promise((resolve, reject) => {
      fetch(
        `/food-waste/wastes/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'POST',
          body: [values],
          token,
          success: (res) => resolve(res),
          failure: (errors) => handleMutationError(errors, reject)
        }
      )
    })
  })

  // modified IQR described by Adrian, see https://linear.app/delicious-data/issue/TEC-2291/implement-frontend-sanity-check-for-abnormal-waste-values#comment-6937f179
  const sanityCheck = async (data) => {
    const date = parseISO(data.date)
    const sixWeeksBefore = addWeeks(date, -6)

    const filters = {
      sales_locations: data.sales_location,
      categories: data.category,
      date_range: `${formatISODate(sixWeeksBefore)},${formatISODate(date)}`
    }

    // not sure of using the cache is a very good idea.
    // If the user add's the last two weeks because of sickness or something, the upper bound will change and should be updated...
    const cacheKey = JSON.stringify(filters)

    if (upperBoundCache.has(cacheKey)) {
      const quartilesResult = upperBoundCache.get(cacheKey)
      return {
        shouldConfirm: quartilesResult == null ? false : data.amount > quartilesResult.b,
        ...quartilesResult
      }
    }

    const pastMeasurements = await new Promise((resolve, reject) => {
      fetch(`/food-waste/wastes/?${queryString.stringify({
        actions: 'group',
        customer,
        ...filters,
        page: 1,
        page_size: 999,
        fields: 'amount',
        open: false
      })}`,
      {
        method: 'GET',
        token,
        success: (res) => resolve(res.results),
        failure: () => resolve([])
      })
    })
    const amountValues = pastMeasurements.map((i) => i.amount).filter(i => i != null && i !== 0)
    if (amountValues.length < 4) {
      // Not enough data to perform sanity check
      upperBoundCache.set(cacheKey, null)
      return {
        shouldConfirm: false
      }
    }

    const quartilesResult = calculateUpperBoundFromQuartiles(amountValues, [0.10, 0.25, 0.75, 0.90])
    upperBoundCache.set(cacheKey, quartilesResult)
    return {
      shouldConfirm: data.amount > quartilesResult.b,
      ...quartilesResult
    }
  }

  const { mutateAsync: deleteOpenMutation, isPending: deleteOpenIsPending } = useMutation({
    mutationFn: (values) => new Promise((resolve, reject) => {
      fetch(
        `/food-waste/wastes/delete-open/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'POST',
          body: values,
          token,
          success: (res) => resolve(res),
          failure: (errors) => handleMutationError(errors, reject)
        }
      )
    }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['waste-measurements'] })
  })

  const { mutateAsync: updateItemMutation, isPending: updateItemIsPending } = useMutation({
    mutationFn: ({ id, ...props }) => new Promise((resolve, reject) => {
      fetch(
        `/food-waste/wastes/${id}/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'PATCH',
          body: props,
          token,
          success: (res) => resolve(res),
          failure: (errors) => handleMutationError(errors, reject)
        }
      )
    }),
    onSuccess: (_data, props) => {
      // if we update the num_sold, this will affect other rows on server side, so we need to invalidate the query
      if (props.num_sold !== undefined) {
        queryClient.invalidateQueries({ queryKey })
      }
    }
  })

  const { mutateAsync: removeMutation, isPending: removeIsPending } = useMutation({
    mutationFn: id => new Promise((resolve, reject) => {
      fetch(
        `/food-waste/wastes/${id}/?${queryString.stringify({
          customer
        })}`,
        {
          method: 'DELETE',
          token,
          success: (res) => resolve(res),
          failure: (errors) => handleMutationError(errors, reject)
        }
      )
    }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['waste-measurements'] })
  })

  const extendedData = useMemo(() => {
    if (!data) return data
    return data.results.map((item) => ({
      ...item,
      open: item.amount == null
    }))
  }, [data])

  // replace the given id in the idCollection with the new id
  const replaceId = (oldId, newId) => {
    if (idCollection) {
      const index = idCollection.indexOf(oldId)
      if (index !== -1) {
        const newCollection = [...idCollection]
        newCollection[index] = newId
        setIdCollection(newCollection)
      }
    }
  }

  // when we're in the open view and are having data, we need to collect the IDs and use them for subsequent requests
  useEffect(() => {
    if (isInOpenView && data && status === 'success' && idCollection === null && data && data.count > 0) {
      setIdCollection(data ? data.results.map((item) => item.id) : [])
      setIdCollectionRealCount(data ? data.count : null)
    }
  }, [data, status, isInOpenView, idCollection])

  const previousData = usePrevious(extendedData)
  const previousStatus = usePrevious(status)

  let returnData = {
    data: data ? extendedData : data,
    status,
    isFetching,
    count: idCollectionRealCount || (data ? data.count : data)
  }

  // FIXME: So, we're faking some data to make open foodwaste editing seamless.
  // But in fact, the items do get lesser, so if I e.g. have 30 items and 25 items per page and I fill them all out,
  // when I go to page 2, we won't get data since page two does not exist anymore. It's maybe edge case and
  // a minor issue, the user can easily go back to page 1 where they see the new data for that page, but it can be confusing.
  if (isInOpenView && idCollection != null && isFetching && previousStatus === 'success' && previousData) {
    returnData = {
      data: previousData,
      status: 'success',
      isFetching: false,
      count: idCollectionRealCount
    }
  }

  return {
    ...returnData,
    invalidate: (all) => queryClient.invalidateQueries({ queryKey: all ? ['waste-measurements'] : queryKey }),
    replaceId,
    sanityCheck,
    add: {
      mutate: addMutation,
      isPending: addIsPending
    },
    remove: {
      mutate: removeMutation,
      isPending: removeIsPending
    },
    updateItem: {
      mutate: updateItemMutation,
      isPending: updateItemIsPending
    },
    bulkDeleteOpen: {
      mutate: deleteOpenMutation,
      isPending: deleteOpenIsPending
    }
  }
}
