import { Input } from 'components/Primitives'
import { useState } from 'react'
import { cronToFriendlyCron } from 'utils/datetime'
import { omit } from 'lodash'

export const CronInput = (props) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}>
      {isFocused
        ? <Input {...props} />
        : <Input
            value={cronToFriendlyCron(props.value)}
            {...omit(props, ['value', 'onChange'])}
          />}
    </div>
  )
}
