import { useMutation } from '@tanstack/react-query'
import globalMessages from 'components/globalMessages'
import { OFFERING_COLUMN_TITLES, FOODWASTE_COLUMN_TITLES } from 'constants/index'
import { omit } from 'lodash'
import queryString from 'query-string'
import { useIntl } from 'react-intl'
import message from 'services/message'
import { usePageSettings } from 'hooks/usePageSettings'
import { convertDataQueryParamsToLegacyParams } from 'utils/converter'
import { formatISODate } from 'utils/datetime'
import { useQueryFetcher, useFoodWasteModeProperties } from 'hooks'
import { useSelectedCustomer } from './useSelectedCustomer'
import { useCurrentColState } from 'components/Grid/hooks/useCurrentColState'
import { INITIAL_FORECAST_COLUMNS_ORDER } from 'routes/forecasts/Productions'
import { INITIAL_FOODWASTE_COLUMNS_ORDER } from 'routes/foodwaste/Foodwaste'
import { useChartQueryDebugger } from './useChartQueryDebugger'
import { DEFAULT_OMIT_FORECAST_CHART } from './useChartQueryModifier'
import { Filter } from './useAvailableFilters'

export const useExport = (options) => {
  const opts = {
    type: 'forecasts',
    ...options
  }
  const intl = useIntl()
  const { fetch, token } = useQueryFetcher()
  const customer = useSelectedCustomer()
  const { invalidFoodwasteCols } = useFoodWasteModeProperties()
  const { settings } = usePageSettings()
  const whereParams = useChartQueryDebugger(DEFAULT_OMIT_FORECAST_CHART)
  const currentColState = useCurrentColState(settings)

  const { mutateAsync, status } = useMutation({
    mutationFn: (params = {}) => new Promise((resolve, reject) => {
      let body, url, conv, fileName, columns

      switch (opts.type) {
        case 'forecasts':
        default:
          columns = currentColState && currentColState.length > 0 ? currentColState.filter(c => !c.hide && !['open', 'actions'].includes(c.colId)).map(c => c.colId) : INITIAL_FORECAST_COLUMNS_ORDER
          const invalidColumnsForExport = ['description']
          // filter out the invalid columns
          columns = columns.filter(c => !invalidColumnsForExport.includes(c))
          conv = convertDataQueryParamsToLegacyParams(
            settings,
            whereParams,
            {
              ...(settings.view === 'grid' ? { ordering: 'date,id' } : undefined),
              ...omit(params, ['sales_locations'])
            },
            {
              ...(params.sales_locations ? { sales_locations: params.sales_locations } : undefined)
            })
          url = `/productions/export/?${queryString.stringify({
            ...conv,
            customer
          })}`
          body = {
            ...omit(conv, ['sales_locations']),
            columns: [...columns, 'forecast_variance', 'sales_loc'].map((column) => ({
              key: column,
              label: OFFERING_COLUMN_TITLES[column]
                ? intl.formatMessage(OFFERING_COLUMN_TITLES[column])
                : column
            }))
          }
          fileName = `export-${formatISODate()}.${params.type === 'excel' ? 'xlsx' : 'csv'}`
          break
        case 'foodwaste':
          columns = currentColState && currentColState.length > 0 ? currentColState.filter(c => !c.hide && !['open', 'actions'].includes(c.colId)).map(c => c.colId) : INITIAL_FOODWASTE_COLUMNS_ORDER.filter(col => !invalidFoodwasteCols.includes(col))
          const omitKeys = ['open']
          columns = columns.filter(c => !omitKeys.includes(c))
          url = `/food-waste/wastes/export/?${queryString.stringify({
            ...(settings[Filter.DATE_RANGE]?.value ? { date_range: settings[Filter.DATE_RANGE].value.join(',') } : undefined),
            ...(settings.location ? { sales_locations: Array.isArray(settings.location) ? settings.location.join(',') : [settings.location] } : undefined),
            ...(params.sales_locations ? { sales_locations: params.sales_locations.join(',') } : undefined),
            ...(settings.wasteCategory ? { categories: settings.wasteCategory.join(',') } : undefined),
            ...((settings.status != null) ? { open: settings.status === 'open' } : undefined),
            ...(opts.ordering ? { ordering: opts.ordering } : { ordering: 'date,sales_location__name,category' }),
            actions: 'group,num_sold,costs,weather,goals',
            page_size: 9999,
            customer
          })}`

          const colMapping = {
            location: 'sales_location',
            sales_location_name: 'sales_location',
            category_name: 'category'
          }

          body = {
            columns: columns.map((column) => {
              const mappedColKey = colMapping[column] || column
              return {
                key: mappedColKey,
                label: FOODWASTE_COLUMN_TITLES[mappedColKey]
                  ? intl.formatMessage(FOODWASTE_COLUMN_TITLES[mappedColKey])
                  : mappedColKey
              }
            })
          }
          fileName = `food-waste-${formatISODate()}.csv`
          break
      }

      fetch(url,
        {
          method: 'POST',
          body,
          fileName,
          token,
          success: (res) => resolve(res),
          failure: (errors) => {
            message.error(intl.formatMessage(globalMessages.exportFailed))
            reject(errors)
          }
        }
      )
    })
  })

  return {
    mutateAsync,
    status
  }
}
