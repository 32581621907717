import { Button } from 'components/Button'
import { Text } from 'components/Primitives'
import titleMessages from 'components/titleMessages'
import { find, findIndex } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import s from '../GridComponents.module.scss'
import { Checkbox } from 'components/Primitives/Checkbox'
import { useGridSettingsHandler } from '../hooks/useGridSettingsHandler'

const setHiddenState = (colState, columnDefinitions, hide) => colState.map((col) => {
  const colDef = find(columnDefinitions, (c) => c.field === col.colId)
  if (col.colId === 'actions' || (colDef && colDef.lockVisible)) return { ...col, hide: false } // actions column is always visible
  return ({ ...col, hide })
})

export const ColumnsToolPanel = ({ api, standalone, standaloneSettings, columnDefinitions, settingsKey, id }) => {
  const { settings } = useGridSettingsHandler({ id, settingsKey, standalone, standaloneSettings })

  const showAll = () => {
    const newColState = setHiddenState(api.getColumnState(), columnDefinitions, false)
    api.applyColumnState({
      state: newColState,
      applyOrder: true
    })
  }
  const hideAll = () => {
    const newColState = setHiddenState(api.getColumnState(), columnDefinitions, true)
    api.applyColumnState({
      state: newColState,
      applyOrder: true
    })
  }

  const colState = useMemo(() => {
    const state = find(settings.colStates, { id: settings.colView })
    // if we don't have a saved state for the current view, we'll return the current state from api
    if (!state) {
      return api.getColumnState()
    }
    return state.state || []
  }, [settings.colStates, settings.colView])

  const handleChange = (colId, checked) => {
    const index = findIndex(colState, { colId })
    const newColState = [...colState]
    let newCol

    if (index === -1) {
      newCol = find(columnDefinitions, { field: colId })
      if (!newCol) return
      newColState.push({ colId, hide: !checked })
    } else {
      newColState[index] = {
        ...newColState[index],
        hide: !checked
      }
    }
    // Apply the new state to the grid. We don't have to save anything, since the grid will take care of that.
    api.applyColumnState({
      state: newColState,
      applyOrder: true
    })
  }

  return (
    <div data-role='toolbar-columns' className={s.toolbarPanel}>
      <div className='flex items-start justify-between'>
        <Text type='table' size='m' className='mb-4'>
          <FormattedMessage tagName='p' {...titleMessages.columnSelection} />
        </Text>
      </div>
      <div className='flex justify-between'>
        <Button type='tertiary' size='s' onClick={showAll} data-action='showAll'>
          <FormattedMessage id='ColumnsToolPanel.showAll' defaultMessage='Show all' />
        </Button>
        <Button type='tertiary' size='s' onClick={hideAll} data-action='hideAll'>
          <FormattedMessage id='ColumnsToolPanel.hideAll' defaultMessage='Hide all' />
        </Button>
      </div>

      <div data-role='columns' className={s.columns}>
        {colState
          .concat(
            columnDefinitions.filter((col) => {
              if (col.colId === 'actions') return false
              const index = findIndex(colState, { colId: col.field })
              return index === -1
            }).map((col) => {
              return ({ colId: col.field, hide: true })
            })
          ).map((col) => {
            const colDef = find(columnDefinitions, (c) => c.field === col.colId && !c.lockVisible)
            if (!colDef) return null
            return <Checkbox key={col.colId} label={colDef.headerName} id={col.colId} size='m' checked={!col.hide} onCheckedChange={(checked) => handleChange(col.colId, checked)} />
          })}
      </div>

    </div>
  )
}
