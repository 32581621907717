import { ADMIN, OPERATOR, USER } from 'constants/index'

export const ROOT_ROUTE = '/'
export const FORECASTS_ROUTE = '/forecasts'
export const DASHBOARD_ROUTE = '/dashboard'
export const FOODWASTE_ROUTE = '/foodwaste'
export const ORDERS_ROUTE = '/order'
export const TODO_ROUTE = '/todo'
export const OPEN_MEASUREMENTS_MODAL = '/enter-measurements'
export const LOGIN_ROUTE = '/login'
export const REGISTER_ROUTE = '/register'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const SET_PASSWORD_ROUTE = '/set-password'
export const LOGOUT_ROUTE = '/logout'
export const REPORT_ROUTE = '/report'

export const CUSTOMERS_ROUTE = '/operator/customers'
export const SCRIPTS_ROUTE = '/operator/scripts'
export const USERS_ROUTE = '/users'
export const ADMIN_USERS_ROUTE = '/admin/users'
export const ADMIN_LOCATIONS_ROUTE = '/admin/locations'
export const ADMIN_ITEMS_ROUTE = '/admin/items'
export const ADMIN_FOODWASTE_ROUTE = '/admin/food-waste'
export const ADMIN_EVENTS_ROUTE = '/admin/events'
export const ADMIN_ORDERCONFIGS_ROUTE = '/admin/order-configs'
export const ADMIN_TODOCONFIGS_ROUTE = '/admin/todo-configs'
export const ADMIN_RECIPELINES_ROUTE = '/admin/recipe-lines'
export const ADMIN_CONTROLLABLE_CLIENT_ROUTE = '/admin/controllable-client'
export const ADMIN_IMPORT_ROUTE = '/admin/import'
export const ADMIN_SFTP_ROUTE = '/admin/sftp'

export const SETTINGS_GENERAL_ROUTE = '/settings/general'
export const SETTINGS_SECURITY_ROUTE = '/settings/security'
export const SETTINGS_USER_ROUTE = '/settings/user'
export const SETTINGS_SUBSCRIPTIONS_ROUTE = '/settings/subscriptions'
export const SETTINGS_ABOUT_ROUTE = '/settings/about'

export const DEVELOPMENT_ROUTE = '/development'

export const CUSTOMER_CONFIG_ROUTE = 'customers-config'
export const SALES_DATA_ROUTE = 'sales-data'
export const SALES_CLUSTERS_ROUTE = 'sales-clusters'
export const SALES_LOCATIONS_ROUTE = 'sales-locations'

export const FOOD_WASTE_CONFIGS_ROUTE = '/food-waste/configs'

export const routeRoleConfig = {
  [ADMIN_USERS_ROUTE]: { roles: [ADMIN, OPERATOR] },
  [ADMIN_LOCATIONS_ROUTE]: { roles: [ADMIN, OPERATOR] },
  [ADMIN_ITEMS_ROUTE]: { roles: [ADMIN, OPERATOR] },
  [ADMIN_FOODWASTE_ROUTE]: { roles: [ADMIN, OPERATOR] },
  [ADMIN_ORDERCONFIGS_ROUTE]: { roles: [ADMIN, OPERATOR] },
  [ADMIN_EVENTS_ROUTE]: { roles: [ADMIN, OPERATOR] },
  [ADMIN_TODOCONFIGS_ROUTE]: { roles: [ADMIN, OPERATOR] },
  [ADMIN_CONTROLLABLE_CLIENT_ROUTE]: { roles: [OPERATOR] },
  [ADMIN_IMPORT_ROUTE]: { roles: [ADMIN, OPERATOR] },
  [ADMIN_SFTP_ROUTE]: { roles: [ADMIN, OPERATOR] },
  [ADMIN_RECIPELINES_ROUTE]: { roles: [ADMIN, OPERATOR] },
  [CUSTOMERS_ROUTE]: { roles: [OPERATOR] },
  [USERS_ROUTE]: { roles: [OPERATOR] },
  [SCRIPTS_ROUTE]: { roles: [OPERATOR] },
  [DASHBOARD_ROUTE]: { roles: [ADMIN, OPERATOR, USER] },
  [FORECASTS_ROUTE]: { roles: [ADMIN, OPERATOR, USER], permissions: ['offering'] },
  [FOODWASTE_ROUTE]: { roles: [ADMIN, OPERATOR, USER], permissions: ['foodwaste'] },
  [ORDERS_ROUTE]: { roles: [ADMIN, OPERATOR] },
  [TODO_ROUTE]: { roles: [ADMIN, OPERATOR, USER], permissions: ['todo'] },
  [DEVELOPMENT_ROUTE]: { roles: [ADMIN, OPERATOR, USER] },
  [SETTINGS_GENERAL_ROUTE]: { roles: [ADMIN, OPERATOR, USER] },
  [SETTINGS_ABOUT_ROUTE]: { roles: [ADMIN, OPERATOR, USER] },
  [SETTINGS_SECURITY_ROUTE]: { roles: [ADMIN, OPERATOR, USER] },
  [SETTINGS_SUBSCRIPTIONS_ROUTE]: { roles: [ADMIN, OPERATOR, USER] },
  [SETTINGS_USER_ROUTE]: { roles: [ADMIN, OPERATOR, USER] },
  [LOGOUT_ROUTE]: { roles: [ADMIN, OPERATOR, USER] },
  [LOGIN_ROUTE]: { roles: null },
  [REGISTER_ROUTE]: { roles: null },
  [FORGOT_PASSWORD_ROUTE]: { roles: null },
  [SET_PASSWORD_ROUTE]: { roles: null },
  [REPORT_ROUTE]: { roles: null }
}
