import { ADMIN_DEFAULT_SETTINGS, CUSTOMERS_DEFAULT_SETTINGS, DASHBOARD_DEFAULT_FILTERS, FOODWASTE_DEFAULT_SETTINGS, FORECAST_DEFAULT_SETTINGS, ORDERS_DEFAULT_SETTINGS, TODO_CONFIG_DEFAULT_SETTINGS } from 'constants/defaultSettings'
import { CUSTOM_RANGE, FLOATING_RANGE } from 'constants/index'
import { differenceInDays, parseISO } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { ADMIN_CONTROLLABLE_CLIENT_ROUTE, ADMIN_EVENTS_ROUTE, ADMIN_FOODWASTE_ROUTE, ADMIN_IMPORT_ROUTE, ADMIN_ITEMS_ROUTE, ADMIN_LOCATIONS_ROUTE, ADMIN_ORDERCONFIGS_ROUTE, ADMIN_RECIPELINES_ROUTE, ADMIN_SFTP_ROUTE, ADMIN_TODOCONFIGS_ROUTE, ADMIN_USERS_ROUTE, CUSTOMERS_ROUTE, DASHBOARD_ROUTE, FOODWASTE_ROUTE, FORECASTS_ROUTE, ORDERS_ROUTE, ROOT_ROUTE, TODO_ROUTE } from 'routes'
import { getInternalPathName, getRangePreset } from 'utils'
import { useSettingsStore } from 'hooks/store/useSettingsStore'
import { setSetting } from 'services/SettingsProvider'
import { Filter } from './useAvailableFilters'
import { calculateFloatingRange } from 'utils/datetime'

const getSettingsKeyByPath = (path) => {
  switch (path) {
    case ROOT_ROUTE:
    case DASHBOARD_ROUTE:
      return 'dashboardFilters'
    case FOODWASTE_ROUTE:
      return 'foodwasteFilters'
    case ORDERS_ROUTE:
      return 'ordersFilters'
    case FORECASTS_ROUTE:
      return 'forecastFilters'
    case TODO_ROUTE:
      return 'todoFilters'
    case ADMIN_USERS_ROUTE:
    case ADMIN_LOCATIONS_ROUTE:
    case ADMIN_ITEMS_ROUTE:
    case ADMIN_FOODWASTE_ROUTE:
    case ADMIN_ORDERCONFIGS_ROUTE:
    case ADMIN_TODOCONFIGS_ROUTE:
    case ADMIN_IMPORT_ROUTE:
    case ADMIN_SFTP_ROUTE:
    case ADMIN_RECIPELINES_ROUTE:
    case ADMIN_EVENTS_ROUTE:
    case ADMIN_CONTROLLABLE_CLIENT_ROUTE:
      return 'adminFilters'
    case CUSTOMERS_ROUTE:
      return 'customers'
    default:
      // TODO: Dynamic Filters for Dashboard UID
      return 'dashboardFilters'
  }
}

const getDefaultSettings = (path) => {
  switch (path) {
    case ROOT_ROUTE:
    case DASHBOARD_ROUTE:
      return DASHBOARD_DEFAULT_FILTERS
    case FORECASTS_ROUTE:
      return FORECAST_DEFAULT_SETTINGS
    case FOODWASTE_ROUTE:
      return FOODWASTE_DEFAULT_SETTINGS
    case ORDERS_ROUTE:
      return ORDERS_DEFAULT_SETTINGS
    case TODO_ROUTE:
      return TODO_CONFIG_DEFAULT_SETTINGS
    case CUSTOMERS_ROUTE:
      return CUSTOMERS_DEFAULT_SETTINGS
    case ADMIN_USERS_ROUTE:
    case ADMIN_LOCATIONS_ROUTE:
    case ADMIN_ITEMS_ROUTE:
    case ADMIN_FOODWASTE_ROUTE:
    case ADMIN_ORDERCONFIGS_ROUTE:
    case ADMIN_TODOCONFIGS_ROUTE:
    case ADMIN_IMPORT_ROUTE:
    case ADMIN_SFTP_ROUTE:
    default:
      return ADMIN_DEFAULT_SETTINGS
  }
}

export const getRangePickerPresets = (path) => {
  if (path.startsWith(DASHBOARD_ROUTE)) {
    return [
      'today',
      'yesterday',
      'tomorrow',
      'thisWeek',
      'weekToDate',
      'lastWeek',
      'thisMonth',
      'monthToDate',
      'lastMonth',
      'thisQuarter',
      'quarterToDate',
      'lastQuarter',
      'thisYear',
      'yearToDate',
      'lastYear'
    ]
  }
  switch (path) {
    case ROOT_ROUTE:
    case DASHBOARD_ROUTE:
      return [
        'today',
        'yesterday',
        'tomorrow',
        'thisWeek',
        'lastWeek',
        'thisMonth',
        'lastMonth',
        'thisQuarter',
        'lastQuarter'
      ]
    case FOODWASTE_ROUTE:
      return [
        'today',
        'yesterday',
        'lastTwoWeeks',
        'thisMonth',
        'lastMonth',
        'thisWeek',
        'lastWeek',
        'nextWeek',
        'next2Week'
      ]
    case ORDERS_ROUTE:
      return [
        'today',
        'tomorrow',
        'tomorrowPlus1'
      ]
    case FORECASTS_ROUTE:
      return [
        'thisWeek',
        'lastWeek',
        'nextWeek',
        'next2Week',
        'next3Week',
        'next4Week'
      ]
    case TODO_ROUTE:
      return [
        'today',
        'tomorrow'
      ]
    case ADMIN_USERS_ROUTE:
    case ADMIN_LOCATIONS_ROUTE:
    case ADMIN_ITEMS_ROUTE:
    case ADMIN_FOODWASTE_ROUTE:
    case ADMIN_ORDERCONFIGS_ROUTE:
    case ADMIN_TODOCONFIGS_ROUTE:
    case ADMIN_IMPORT_ROUTE:
    case ADMIN_SFTP_ROUTE:
    case ADMIN_EVENTS_ROUTE:
      return [
        'today',
        'thisWeek',
        'lastWeek',
        'thisMonth',
        'lastMonth',
        'nextWeek',
        'next2Week',
        'next3Week',
        'next4Week'
      ]
    case 'defaultPresets':
      return [
        'today',
        'yesterday',
        'lastWeek',
        'thisMonth',
        'lastMonth',
        'thisQuarter',
        'lastQuarter'
      ]
    default:
      return []
  }
}

export const usePageSettings = () => {
  const location = useLocation()
  const { pathname: corePathName } = location

  const pathname = getInternalPathName(corePathName)
  const settings = useSettingsStore(state => state.settings)
  const isInitialized = useSettingsStore(state => state.isInitialized)
  const nonPersistentSettings = useSettingsStore(state => state.nonPersistentSettings)
  const setNonPersistentSettings = useSettingsStore(state => state.setNonPersistentSettings)

  const rangePresets = useMemo(() => getRangePickerPresets(pathname), [pathname])

  const pageSettings = useMemo(() => {
    if (!isInitialized) return {}
    const key = getSettingsKeyByPath(pathname)
    if (!key) {
      return {
        ...nonPersistentSettings
      }
    }
    let resultSet = {
      ...getDefaultSettings(pathname),
      ...settings[key],
      ...nonPersistentSettings
    }

    // we have to ensure there is no range vs rangeOption mismatch!
    if (resultSet[Filter.DATE_RANGE]?.option && ![CUSTOM_RANGE, FLOATING_RANGE].includes(resultSet[Filter.DATE_RANGE].option)) {
      resultSet = {
        ...resultSet,
        [Filter.DATE_RANGE]: {
          value: getRangePreset(resultSet[Filter.DATE_RANGE].option, true),
          option: resultSet[Filter.DATE_RANGE].option
        }
      }
    }

    return resultSet
  }, [settings, nonPersistentSettings, pathname])

  const set = useCallback((data, persistent = true, overwrite = false) => {
    if (persistent) {
      const key = getSettingsKeyByPath(pathname)
      const state = useSettingsStore.getState()
      setSetting({
        [key]: overwrite
          ? data
          : {
              ...state.settings[key],
              ...data
            }
      })
    } else {
      setNonPersistentSettings({
        ...nonPersistentSettings,
        ...data
      })
    }
  }, [pathname, settings, nonPersistentSettings, setNonPersistentSettings])

  const shouldAggregateByWeek = useMemo(() => {
    if (!pageSettings || !pageSettings[Filter.DATE_RANGE] || pageSettings[Filter.DATE_RANGE].value.length === 0 || (pageSettings[Filter.DATE_RANGE].value.from && !pageSettings[Filter.DATE_RANGE].value.from.unit)) return false
    const realRangeValue = pageSettings[Filter.DATE_RANGE].option === FLOATING_RANGE
      ? calculateFloatingRange(pageSettings[Filter.DATE_RANGE].value)
      : [parseISO(pageSettings[Filter.DATE_RANGE].value[0], parseISO(pageSettings[Filter.DATE_RANGE].value[1]))]

    return differenceInDays(realRangeValue[1], realRangeValue[0]) > 100
  }, [pageSettings])

  return {
    settings: pageSettings,
    set,
    rangePresets,
    shouldAggregateByWeek,
    isInitialized
  }
}
