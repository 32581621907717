// Validator functions to keep user settings healthy over time - validate settings and fix or remove them
// FIXME: Operators can do anything. Maybe it would be better to have such things in the customer config?

import { BAKERY, CUSTOM_RANGE } from 'constants/index'
import { getAvailableFilterKeys, getMappedFilterSetting } from 'hooks/useAvailableFilters'
import { uniq, flatten, some, pick, omit, cloneDeep, get, set, unset } from 'lodash'
import { CUSTOMERS_ROUTE, DASHBOARD_ROUTE, FOODWASTE_ROUTE, FORECASTS_ROUTE, ORDERS_ROUTE } from 'routes'
import { FOODWASTE_CHARTS, FORECAST_CHARTS, ORDER_CHARTS, TODO_CHARTS } from 'routes/dashboard/constants'
import { getRangePreset } from 'utils'

const vaildFoodwasteTableSize = (userSettings) => {
  if (!userSettings.foodwasteFilters) return userSettings
  if (!userSettings.foodwasteFilters.pagination) return userSettings

  if (userSettings.foodwasteFilters.pagination && (userSettings.foodwasteFilters.pagination.pageSize === 100 || userSettings.foodwasteFilters.pagination.pageSize === 75)) {
    userSettings.foodwasteFilters.pagination.pageSize = 25
  }
  return userSettings
}

/** Process the dashboard configuration to ensure a valid config based on permissions */
const validDashboard = (userSettings, permissions, customerType) => {
  if (!userSettings.dashboard || !permissions.isInitialized) return userSettings

  const allowedKeys = [
    ...FORECAST_CHARTS,
    ...(customerType === BAKERY ? ORDER_CHARTS : []),
    ...(permissions.todo ? TODO_CHARTS : []),
    ...(permissions.foodwaste ? FOODWASTE_CHARTS : [])
  ]
  return {
    ...userSettings,
    dashboard: userSettings.dashboard.filter(chart => allowedKeys.includes(chart.i))
  }
}

const fixStringCollection = (collection) => {
  const invalid = some(collection, (v) => typeof v === 'string' && v.includes(','))
  if (invalid) {
    return uniq(flatten(collection.map(v => typeof v === 'string' && v.includes(',') ? v.split(',') : v)))
  }
  return collection
}

/** Catch and repair broken filters */
const validFilters = (userSettings, permissions, customerType) => {
  if (!userSettings.dashboardFilters) return userSettings

  // this is the list of all available filter settings.
  // we use it to cleanup the user settings and only allow defined settings
  const allowedFilterSettings = {
    dashboard: getAvailableFilterKeys(DASHBOARD_ROUTE, customerType, true, permissions).map(k => getMappedFilterSetting(k, DASHBOARD_ROUTE)).flat(),
    offering: getAvailableFilterKeys(FORECASTS_ROUTE, customerType, true, permissions).map(k => getMappedFilterSetting(k, FORECASTS_ROUTE)).flat(),
    foodwaste: getAvailableFilterKeys(FOODWASTE_ROUTE, customerType, true, permissions).map(k => getMappedFilterSetting(k, FOODWASTE_ROUTE)).flat(),
    orders: getAvailableFilterKeys(ORDERS_ROUTE, customerType, true, permissions).map(k => getMappedFilterSetting(k, ORDERS_ROUTE)).flat(),
    customers: getAvailableFilterKeys(CUSTOMERS_ROUTE, customerType, true, permissions).map(k => getMappedFilterSetting(k, CUSTOMERS_ROUTE)).flat()
  }

  const newDashboardFilters = {
    ...pick(userSettings.dashboardFilters, allowedFilterSettings.dashboard)
  }

  const result = {
    ...userSettings,
    dashboardFilters: {
      ...newDashboardFilters,
      ...(newDashboardFilters.location ? { location: fixStringCollection(newDashboardFilters.location) } : undefined),
      ...(newDashboardFilters.wasteCategory ? { wasteCategory: fixStringCollection(newDashboardFilters.wasteCategory) } : undefined),
      ...(newDashboardFilters.dateRange
        ? {
            dateRange: omit(newDashboardFilters.dateRange, ['comparingPeriod'])
          }
        : undefined)
    },
    ...(userSettings.forecastFilters
      ? {
          forecastFilters: {
            ...pick(userSettings.forecastFilters, ['view', 'fields', 'search', 'colView', 'colStates', 'pagination', 'og2Collapsed', 'colDensity']),
            ...pick(userSettings.forecastFilters, allowedFilterSettings.offering)
          }
        }
      : undefined),
    ...(userSettings.foodwasteFilters
      ? {
          foodwasteFilters: {
            ...pick(userSettings.foodwasteFilters, ['colView', 'colStates', 'pagination', 'colDensity']),
            ...pick(userSettings.foodwasteFilters, allowedFilterSettings.foodwaste)
          }
        }
      : undefined),
    ...(userSettings.ordersFilters
      ? {
          ordersFilters: {
            ...pick(userSettings.ordersFilters, ['colView', 'colStates', 'pagination', 'colDensity']),
            ...pick(userSettings.ordersFilters, allowedFilterSettings.orders)
          }
        }
      : undefined),
    ...(userSettings.customers
      ? {
          customers: {
            ...pick(userSettings.customers, ['colView', 'colStates', 'pagination', 'colDensity', 'search']),
            ...pick(userSettings.customers, allowedFilterSettings.customers)
          }
        }
      : undefined)
  }

  // ensure that dashboardFilters don't  have a customRange option with empty value
  if (result.dashboardFilters?.dateRange?.option === CUSTOM_RANGE && result.dashboardFilters?.dateRange?.value.length === 0) {
    const preset = getRangePreset('yesterday', true)
    const newDateRange = {
      value: preset,
      option: 'yesterday'
    }
    result.dashboardFilters.dateRange = newDateRange
  }

  return result
}

// export const ensureEffectiveRequestedAmount = (userSettings) => {
//   const result = {
//     ...userSettings,
//     ...(userSettings.ordersFilters
//       ? {
//           ordersFilters: {
//             ...userSettings.ordersFilters,
//             colStates: userSettings.ordersFilters.colStates
//               ? userSettings.ordersFilters.colStates.map((colState) => {
//                   return {
//                     ...colState,
//                     state: colState.state.map((state) => {
//                       if (state.colId === 'requested_amount') {
//                         return {
//                           ...state,
//                           colId: 'effective_requested_amount'
//                         }
//                       } else {
//                         return state
//                       }
//                     })
//                   }
//                 })
//               : userSettings.ordersFilters.colStates
//           }
//         }
//       : undefined)
//   }

//   return result
// }

const GRID_MIGRATION = [
  {
    oldPath: 'foodwasteFilters',
    id: 'foodwaste'
  },
  {
    oldPath: 'forecastFilters',
    id: 'offering-table'
  },
  {
    oldPath: 'ordersFilters',
    id: 'orders'
  },
  {
    oldPath: 'adminFilters.events',
    id: 'admin-events'
  },
  {
    oldPath: 'adminFilters.foodwasteConfigs',
    id: 'admin-foodwaste-configs'
  },
  {
    oldPath: 'adminFilters.importedFiles',
    id: 'admin-import'
  },
  {
    oldPath: 'adminFilters.items',
    id: 'admin-items'
  },
  {
    oldPath: 'adminFilters.locations',
    id: 'admin-locations'
  },
  {
    oldPath: 'adminFilters.recipeLines',
    id: 'admin-recipe-lines'
  },
  {
    oldPath: 'adminFilters.users',
    id: 'admin-users'
  },
  {
    oldPath: 'adminFilters.orderConfigs.batchRounding',
    id: 'admin-order-configs-batch-rounding'
  },
  {
    oldPath: 'adminFilters.orderConfigs.fulfillmentDelay',
    id: 'admin-order-configs-fulfillment-delay'
  },
  {
    oldPath: 'adminFilters.orderConfigs.multidayTracking',
    id: 'admin-order-configs-multiday-tracking'
  },
  {
    oldPath: 'adminFilters.orderConfigs.orderFactor',
    id: 'admin-order-configs-order-factor'
  },
  {
    oldPath: 'adminFilters.orderConfigs.ordersEnabled',
    id: 'admin-order-configs-orders-enabled'
  },
  {
    oldPath: 'adminFilters.orderConfigs.quantile',
    id: 'admin-order-configs-quantile'
  },
  {
    oldPath: 'adminFilters.orderConfigs.referenceItem',
    id: 'admin-order-configs-reference-item'
  },
  {
    oldPath: 'adminFilters.todoConfigs.initialTodos',
    id: 'admin-todo-configs-initial-todos'
  },
  {
    oldPath: 'adminFilters.todoConfigs.inventoryGroup',
    id: 'admin-todo-configs-inventory-group'
  },
  {
    oldPath: 'adminFilters.todoConfigs.scheduling',
    id: 'admin-todo-configs-scheduling'
  },
  {
    oldPath: 'adminFilters.todoConfigs.totalQuantile',
    id: 'admin-todo-configs-total-quantile'
  }
]
// we migrate the grid settings in the route based keys to the new global key system
export const migrateGridSettings = (userSettings) => {
  // console.log('🥳 ~ migrateridSettings', userSettings)
  const clonedSettings = cloneDeep(userSettings)

  GRID_MIGRATION.forEach((migration) => {
    // console.log('🥳 ~ migrateGridSettings ~ migration', migration)
    const oldSettings = get(userSettings, migration.oldPath)
    if (!oldSettings) {
      // console.log(`🥳 ~ migrateGridSettings ~ no settings found for ${migration.oldPath}`)
      return
    }
    const gridSettings = pick(oldSettings, ['colView', 'colStates', 'pagination', 'colDensity', 'sortModel'])
    if (Object.keys(gridSettings).length === 0) {
      // console.log(`🥳 ~ migrateGridSettings ~ no grid settings found for ${migration.oldPath}`)
      return
    }

    const cleanedSettings = omit(oldSettings, ['colView', 'colStates', 'pagination', 'colDensity', 'sortModel', 'ordering', 'page'])

    const newKey = `grid-${migration.id}`
    // console.log('🥳 ~ migrateGridSettings ~ newKey', newKey, Object.keys(cleanedSettings))
    if (Object.keys(cleanedSettings).length > 0) {
      set(clonedSettings, migration.oldPath, cleanedSettings)
    } else {
      unset(clonedSettings, migration.oldPath)
    }

    if (!gridSettings.colStates) {
      // console.log(`🥳 ~ migrateGridSettings ~ no colStates found for ${migration.oldPath}... deleted and not migrated`)
      return
    }

    clonedSettings[newKey] = gridSettings
  })

  // console.log('🥳 ~ migrateGridSettings ~ new settings', clonedSettings)

  return clonedSettings
}

export const validSettings = (userSettings, permissions, customerType) => {
  const withValidDashboard = validDashboard(userSettings, permissions, customerType)
  return migrateGridSettings(validFilters(vaildFoodwasteTableSize(withValidDashboard), permissions, customerType))
}
