import { Text } from 'components/Primitives'
import { FormattedMessage } from 'react-intl'

const renderErrorMessage = (error) => {
  switch (error) {
    default:
      return <FormattedMessage id='ErrorIndicator.validationFailed' defaultMessage='Form validation failed.' />
    case 'at-least-one-field-set':
      return <FormattedMessage id='ErrorIndicator.atLeastOneFieldSet' defaultMessage='At least one of the following fields must be filled in.' />
  }
}

export const ErrorIndicator = ({ errors, errorKey }) => {
  const hasError = errors[errorKey] != null
  if (!hasError) return null
  return <Text color='danger'>{renderErrorMessage(errors[errorKey])}</Text>
}
