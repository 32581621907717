import { Icon } from 'components/Primitives'
import ReactDOMServer from 'react-dom/server'

export const icons = {
  // tool panel column group contracted (click to expand)
  columnSelectClosed: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // tool panel column group expanded (click to contract)
  columnSelectOpen: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // column tool panel header expand/collapse all button, shown when some children are expanded and
  //     others are collapsed
  columnSelectIndeterminate: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on drag and drop image component icon while dragging column to the side of the grid to pin
  columnMovePin: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on drag and drop image component icon while dragging over part of the page that is not a drop zone
  columnMoveHide: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on drag and drop image component icon while dragging columns to reorder
  columnMoveMove: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // animating icon shown when dragging a column to the right of the grid causes horizontal scrolling
  columnMoveLeft: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // animating icon shown when dragging a column to the left of the grid causes horizontal scrolling
  columnMoveRight: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on drag and drop image component icon while dragging over Row Groups drop zone
  columnMoveGroup: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on drag and drop image component icon while dragging over Values drop zone
  columnMoveValue: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on drag and drop image component icon while dragging over pivot drop zone
  columnMovePivot: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on drag and drop image component icon while dragging over drop zone that doesn't support it, e.g.
  //     string column over aggregation drop zone
  dropNotAllowed: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on row group when contracted (click to expand)
  groupContracted: ReactDOMServer.renderToString(<Icon size='xs' name='ChevronRight' />),
  // shown on row group when expanded (click to contract)
  groupExpanded: ReactDOMServer.renderToString(<Icon size='xs' name='ChevronDown' />),
  // set filter tree list group contracted (click to expand)
  setFilterGroupClosed: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // set filter tree list group expanded (click to contract)
  setFilterGroupOpen: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // set filter tree list expand/collapse all button, shown when some children are expanded and
  //     others are collapsed
  setFilterGroupIndeterminate: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // context menu chart item
  chart: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // chart window title bar
  close: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // X (remove) on column 'pill' after adding it to a drop zone list
  cancel: ReactDOMServer.renderToString(<Icon flexWrapped size='xs' name='Close' />),
  // indicates the currently active pin state in the "Pin column" sub-menu of the column menu
  check: ReactDOMServer.renderToString(<Icon flexWrapped size='xs' name='Checkmark' />),
  // "go to first" button in pagination controls
  first: ReactDOMServer.renderToString(<Icon size='xs' name='ChevronLeftDouble' />),
  // "go to previous" button in pagination controls
  previous: ReactDOMServer.renderToString(<Icon size='xs' name='ChevronLeft' />),
  // "go to next" button in pagination controls
  next: ReactDOMServer.renderToString(<Icon size='xs' name='ChevronRight' />),
  // "go to last" button in pagination controls
  last: ReactDOMServer.renderToString(<Icon size='xs' name='ChevronRightDouble' />),
  // shown on top right of chart when chart is linked to range data (click to unlink)
  linked: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on top right of chart when chart is not linked to range data (click to link)
  unlinked: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // "Choose colour" button on chart settings tab
  colorPicker: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // rotating spinner shown by the loading cell renderer
  // groupLoading: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // button to launch enterprise column menu
  menu: ReactDOMServer.renderToString(<Icon flexWrapped size='xs' name='OverflowMenuVertical' />),
  // filter tool panel tab
  filter: ReactDOMServer.renderToString(<Icon size='xs' name='Filter' />),
  // column tool panel tab
  columns: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // button in chart regular size window title bar (click to maximise)
  maximize: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // button in chart maximised window title bar (click to make regular size)
  minimize: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // "Pin column" item in column header menu
  menuPin: ReactDOMServer.renderToString(<Icon flexWrapped size='xs' name='OpenPanelLeft' />),
  // "Value aggregation" column menu item (shown on numeric columns when grouping is active)"
  menuValue: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // "Group by {column-name}" item in column header menu
  menuAddRowGroup: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // "Un-Group by {column-name}" item in column header menu
  menuRemoveRowGroup: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // context menu copy item
  clipboardCopy: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // context menu cut item
  clipboardCut: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // context menu paste item
  clipboardPaste: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // identifies the pivot drop zone
  pivotPanel: ReactDOMServer.renderToString(<Icon size='xs' name='RowExpand' />),
  // "Row groups" drop zone in column tool panel
  rowGroupPanel: ReactDOMServer.renderToString(<Icon size='xs' name='ExpandCategories' />),
  // columns tool panel Values drop zone
  valuePanel: ReactDOMServer.renderToString(<Icon size='xs' name='Sigma' />),
  // drag handle used to pick up draggable columns
  columnDrag: ReactDOMServer.renderToString(<Icon size='xs' name='Draggable' />),
  // drag handle used to pick up draggable rows
  rowDrag: ReactDOMServer.renderToString(<Icon size='xs' name='Draggable' />),
  // context menu export item
  save: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // csv export
  csvExport: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // excel export
  excelExport: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // icon on dropdown editors
  smallDown: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // version of small-right used in RTL mode
  smallLeft: ReactDOMServer.renderToString(<Icon flexWrapped size='xs' name='ChevronLeft' />),
  // separater between column 'pills' when you add multiple columns to the header drop zone
  smallRight: ReactDOMServer.renderToString(<Icon flexWrapped size='xs' name='ChevronRight' />),
  smallUp: ReactDOMServer.renderToString(<Icon flexWrapped size='xs' name='ChevronUp' />),
  // show on column header when column is sorted ascending
  sortAscending: ReactDOMServer.renderToString(<Icon flexWrapped size='xs' name='SortAscending' />),
  // show on column header when column is sorted descending
  sortDescending: ReactDOMServer.renderToString(<Icon flexWrapped size='xs' name='SortDescending' />),
  // show on column header when column has no sort, only when enabled with gridOptions.unSortIcon=true
  sortUnSort: ReactDOMServer.renderToString(<Icon flexWrapped size='xs' name='SortRemove' />),
  // Builder button in Advanced Filter
  advancedFilterBuilder: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // drag handle used to pick up Advanced Filter Builder rows
  advancedFilterBuilderDrag: ReactDOMServer.renderToString(<Icon flexWrapped size='xs' name='Draggable' />),
  // Advanced Filter Builder row validation error
  advancedFilterBuilderInvalid: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on Advanced Filter Builder rows to move them up
  advancedFilterBuilderMoveUp: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on Advanced Filter Builder rows to move them down
  advancedFilterBuilderMoveDown: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on Advanced Filter Builder rows to add new rows
  advancedFilterBuilderAdd: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown on Advanced Filter Builder rows to remove row
  advancedFilterBuilderRemove: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // Edit Chart menu item shown in Integrated Charts menu
  chartsMenuEdit: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // Advanced Settings menu item shown in Integrated Charts menu
  chartsMenuAdvancedSettings: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // shown in Integrated Charts menu add fields
  chartsMenuAdd: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // checked checkbox
  checkboxChecked: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // indeterminate checkbox
  checkboxIndeterminate: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // unchecked checkbox
  checkboxUnchecked: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // radio button on
  radioButtonOn: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />),
  // radio button off
  radioButtonOff: ReactDOMServer.renderToString(<Icon size='xs' name='tbd' />)
}
