import {
  ADMIN_FOODWASTE_ROUTE,
  ADMIN_IMPORT_ROUTE,
  ADMIN_ITEMS_ROUTE,
  ADMIN_LOCATIONS_ROUTE,
  ADMIN_ORDERCONFIGS_ROUTE,
  ADMIN_SFTP_ROUTE,
  ADMIN_TODOCONFIGS_ROUTE,
  ADMIN_USERS_ROUTE,
  CUSTOMERS_ROUTE,
  DASHBOARD_ROUTE,
  FOODWASTE_ROUTE,
  FORECASTS_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  ROOT_ROUTE,
  SET_PASSWORD_ROUTE,
  TODO_ROUTE,
  USERS_ROUTE,
  DEVELOPMENT_ROUTE,
  SCRIPTS_ROUTE,
  ADMIN_RECIPELINES_ROUTE,
  ADMIN_EVENTS_ROUTE,
  ORDERS_ROUTE,
  SETTINGS_ABOUT_ROUTE,
  SETTINGS_GENERAL_ROUTE,
  SETTINGS_SECURITY_ROUTE,
  SETTINGS_SUBSCRIPTIONS_ROUTE,
  SETTINGS_USER_ROUTE,
  ADMIN_CONTROLLABLE_CLIENT_ROUTE
} from '../../routes'
import React, { useCallback, useEffect, useState } from 'react'
import { useHomebarHeight, useIsMobile, useMobileScrollEvent } from 'hooks'

import BottomMenu from 'components/BottomMenu'
import { DebugConsole } from 'components/DebugConsole'
import Footer from 'components/Footer'
import Header from 'components/Header'
import Media from 'components/Media'
import OfflineNotification from '../OfflineNotification'
import PageErrorBoundary from '../PageErrorBoundary'
import { Sidebar } from 'components/Sidebar'
import { ToastContainer } from 'react-toastify'
import WebSocketHandler from 'components/WebSocketHandler'
import classNames from 'classnames'
import m from 'moment'
import useIsDesktop from 'hooks/useIsDesktop'
import { useLocation } from 'react-router-dom'
import { useMount } from 'ahooks'
import { useUpdateHandler } from '../../hooks'
import AddToHomescreenNotification from '../AddToHomescreenNotification'
import { DebugModal } from 'components/DebugModal'
import { DebugBanner } from 'components/DebugBanner'
import { BugReportModal } from 'components/BugReportModal'
import { useGeneralStore } from 'hooks/store/useGeneralStore'
import s from './Layout.module.scss'
import { SettingsProvider } from 'services/SettingsProvider'
import { ModalContainer } from 'components/ModalContainer'
import { ImpersonationBanner } from 'components/ImpersonationBanner'
import { getInternalPathName } from 'utils'
import { OperatorPanel } from 'components/OperatorPanel'
import { HelpCenter } from 'components/HelpCenter'

const ROUTES_WITH_SIDEBAR = [
  CUSTOMERS_ROUTE,
  USERS_ROUTE,
  ADMIN_FOODWASTE_ROUTE,
  ADMIN_IMPORT_ROUTE,
  ADMIN_ITEMS_ROUTE,
  ADMIN_LOCATIONS_ROUTE,
  ADMIN_ORDERCONFIGS_ROUTE,
  ADMIN_EVENTS_ROUTE,
  ADMIN_SFTP_ROUTE,
  ADMIN_TODOCONFIGS_ROUTE,
  ADMIN_CONTROLLABLE_CLIENT_ROUTE,
  ADMIN_RECIPELINES_ROUTE,
  ADMIN_USERS_ROUTE,
  FORECASTS_ROUTE,
  SETTINGS_ABOUT_ROUTE,
  SETTINGS_GENERAL_ROUTE,
  SETTINGS_SECURITY_ROUTE,
  SETTINGS_SUBSCRIPTIONS_ROUTE,
  SETTINGS_USER_ROUTE,
  DASHBOARD_ROUTE,
  ROOT_ROUTE,
  FOODWASTE_ROUTE,
  ORDERS_ROUTE,
  TODO_ROUTE,
  DEVELOPMENT_ROUTE,
  SCRIPTS_ROUTE
]

const ROUTES_WITH_HEADER = [
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  SET_PASSWORD_ROUTE
]

m.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week,
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  }
})

const AppLayout = ({ children }) => {
  const { pathname } = useLocation()
  const locale = useGeneralStore(state => state.locale)

  const currentRouteName = getInternalPathName(pathname)
  const sidebarVisible = ROUTES_WITH_SIDEBAR.includes(currentRouteName)
  const headerVisible = ROUTES_WITH_HEADER.includes(currentRouteName)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

  const onCollapsedChange = useCallback((collapsed) => setSidebarCollapsed(collapsed), [])

  useMount(() => {
    // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
    document.documentElement.style.setProperty(
      '--vh',
        `${window.innerHeight / 100}px`
    )
  })

  useUpdateHandler()

  const isDesktop = useIsDesktop()

  const leftMargin = () => {
    if (!sidebarVisible || !isDesktop) { return 0 }
    return sidebarCollapsed ? '80px' : '237px'
  }

  // Update moment locale when locale changed
  useEffect(() => {
    m.locale(locale.substr(0, 2))
  }, [locale])

  // on iOS we have to detect scroll events to enforce a rerender
  useMobileScrollEvent({ enabled: window.navigator.platform === 'iPhone' })
  const homebarHeight = useHomebarHeight()
  const isMobile = useIsMobile()

  const marginBottom = isDesktop ? null : `calc(100vh - ${isMobile ? '3rem' : '4rem'} - ${homebarHeight > 0 ? homebarHeight : 0}px)`

  return (
    <section className={classNames(s.layout, s.container)}>
      {sidebarVisible && (
        <Media className='flex' greaterThanOrEqual='tablet'>
          <Sidebar onCollapsedChange={onCollapsedChange} />
        </Media>)}
      <section
        className={classNames(s.layout, s.inner, headerVisible ? 'flex-col' : null)}
        style={{ marginLeft: leftMargin() }}
      >
        {headerVisible && <Header />}
        <main className={s.content} style={{ height: marginBottom }} data-cy='layout-main'>
          <PageErrorBoundary key={currentRouteName}>
            {children}
          </PageErrorBoundary>
        </main>
        {headerVisible && <Footer />}
        <WebSocketHandler />
        <DebugConsole />
        <DebugModal />
        <DebugBanner />
        <ImpersonationBanner />
        <BugReportModal />
        <OfflineNotification />
        <AddToHomescreenNotification />
        <SettingsProvider />
        <ModalContainer />
        <OperatorPanel />
        <HelpCenter />
        <ToastContainer
          position='top-center'
          autoClose={5000}
          closeOnClick
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme='colored'
          limit={5}
        />
      </section>
      {sidebarVisible && <Media lessThan='tablet'><BottomMenu /></Media>}
    </section>
  )
}

export default AppLayout
