import * as RadixDialog from '@radix-ui/react-dialog'
import * as RadixAlertDialog from '@radix-ui/react-alert-dialog'
import s from './Dialog.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import globalMessages from 'components/globalMessages'
import { DialogFooter } from './DialogFooter'
import { isEmpty } from 'utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDebounceEffect, useSize, useUpdate } from 'ahooks'
import classNames from 'classnames'
import RoundedButton from 'components/RoundedButton'
import { ProIcon } from 'components/ProIcon'

/**
 * footer props
 * {
 *  primaryText?: string;
 *  cancelText?: string;
 *  primaryLoading?: boolean;
 *  primaryDisabled?: boolean;
 *  hideCancel?: boolean;
 * }
 */

const AVAILABLE_SIZES = ['s', 'm', 'l', 'xl', 'xxl']

export const Dialog = ({ title, open, onClose, onPrimary, text, undoWarning, children, footer, danger, confirm, noFooter, noHeader, noPadding, forceMount, popupContainerClassName, closable = true, size = 's', childrenWrapperClass, blocking, innerContentClassName, forDropdown, progress, getDialogSize, proPreview }) => {
  const intl = useIntl()
  const childAsFunc = useMemo(() => {
    return typeof (children) === 'function'
  }, [children])

  const realSize = AVAILABLE_SIZES.includes(size) ? size : 's'

  const [submitted, setSubmitted] = useState(false)
  const popupContainer = useRef()
  const update = useUpdate()
  const onOpenChange = (open) => {
    if (!open && closable) {
      onClose()
    }
  }

  useEffect(() => {
    if (open) {
      setSubmitted(false)
    }
  }, [open])

  const Base = confirm ? RadixAlertDialog : RadixDialog

  const closeButton = (
    <RoundedButton
      className={s.closeButton}
      aria-label={intl.formatMessage(globalMessages.close)}
      icon='Close'
      type='secondary'
      data-action='close-modal'
    />
  )

  const onlyChildren = isEmpty(text) && !undoWarning

  // For an unknown reason, we can't use the popup container ref.
  // When accessing popupContainer, we see that `current` is set to div,
  // but when accessing popupContainer.current, it's null.
  // This makes absolutely no sense from JS perspective.
  // We workaround this by using a debounced effect and trigger a re-render.
  useDebounceEffect(() => {
    if (open && childAsFunc) {
      update()
    }
  }, [open, childAsFunc], { wait: 50 })

  const renderInner = () => {
    if (!childAsFunc) { return children }

    if (popupContainer.current == null) {
      return null
    }

    return children(popupContainer.current)
  }

  const onPrimaryClick = onPrimary
    ? (e, action) => {
        if (confirm) {
          setSubmitted(true)
        }
        onPrimary(e, action)
      }
    : undefined

  const contentRef = useRef(null)
  const dialogSize = useSize(contentRef)
  useEffect(() => {
    if (getDialogSize) getDialogSize(contentRef.current?.getBoundingClientRect())
  }, [getDialogSize, contentRef, dialogSize])

  return (
    <Base.Root open={open} onOpenChange={onOpenChange}>
      <Base.Portal>
        <Base.Overlay className={s.overlay}>
          <Base.Content forceMount={forceMount} className={classNames(s.content, s[realSize], forDropdown && s.forDropdown)} onInteractOutside={blocking ? (e) => e.preventDefault() : undefined} ref={contentRef}>
            <div ref={popupContainer} id='dialogPopupContainer' className={popupContainerClassName} />
            {!noHeader && (
              <Base.Title className={classNames('modal-header', s.title)}>
                {title}
                {proPreview && <ProIcon className='ml-4 mr-5' delayedTooltip />}
              </Base.Title>
            )}
            <div
              className={classNames('modal-body', s.innerContent, innerContentClassName, {
                [s.noFooter]: noFooter,
                [s.noHeader]: noHeader,
                [s.noPadding]: noPadding
              })}
            >
              {text && <Base.Description className={s.description}>{text}</Base.Description>}
              {undoWarning && <p className={s.noUndo}><FormattedMessage {...globalMessages.noUndo} /></p>}
              {children && (
                <div className={classNames(onlyChildren ? null : 'mt-4', childrenWrapperClass)}>
                  {renderInner()}
                </div>
              )}
            </div>
            {!noFooter && <DialogFooter onCancel={closable ? onClose : undefined} onPrimary={onPrimaryClick} danger={danger} confirm={confirm} progress={progress} {...footer} primaryLoading={submitted || (footer && footer.primaryLoading)} />}
            {!noHeader
              ? confirm
                  ? <RadixAlertDialog.Cancel className={classNames('modal-close', s.close)} asChild>{closeButton}</RadixAlertDialog.Cancel>
                  : <RadixDialog.Close className={classNames('modal-close', s.close)} asChild>{closeButton}</RadixDialog.Close>
              : null}
          </Base.Content>
        </Base.Overlay>
      </Base.Portal>
    </Base.Root>
  )
}
