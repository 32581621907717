import { useMutation } from '@tanstack/react-query'
import queryString from 'query-string'
import { useQueryFetcher } from 'hooks'
import { useSelectedCustomer } from './useSelectedCustomer'

export const useCreateClientRequest = () => {
  const { fetch, token } = useQueryFetcher()
  const customer = useSelectedCustomer()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ method, params }) => new Promise((resolve, reject) => {
      fetch(`/clients/create-request?${queryString.stringify({ customer })}`,
        {
          method: 'POST',
          body: {
            method,
            params
          },
          token,
          success: (res) => resolve(res),
          failure: (errors) => { reject(errors) }
        }
      )
    })
  })

  return {
    mutateAsync,
    isPending
  }
}
