import {
  ADMIN_FOODWASTE_ROUTE,
  ADMIN_IMPORT_ROUTE,
  ADMIN_ITEMS_ROUTE,
  ADMIN_LOCATIONS_ROUTE,
  ADMIN_ORDERCONFIGS_ROUTE,
  ADMIN_EVENTS_ROUTE,
  ADMIN_SFTP_ROUTE,
  ADMIN_TODOCONFIGS_ROUTE,
  ADMIN_USERS_ROUTE,
  CUSTOMERS_ROUTE,
  DASHBOARD_ROUTE,
  FOODWASTE_ROUTE,
  FORECASTS_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  ROOT_ROUTE,
  SET_PASSWORD_ROUTE,
  TODO_ROUTE,
  USERS_ROUTE,
  DEVELOPMENT_ROUTE,
  SCRIPTS_ROUTE,
  ADMIN_RECIPELINES_ROUTE,
  ORDERS_ROUTE,
  SETTINGS_GENERAL_ROUTE,
  SETTINGS_SECURITY_ROUTE,
  SETTINGS_USER_ROUTE,
  SETTINGS_SUBSCRIPTIONS_ROUTE,
  SETTINGS_ABOUT_ROUTE,
  REPORT_ROUTE,
  ADMIN_CONTROLLABLE_CLIENT_ROUTE
} from 'routes'
import { Route, Routes } from 'react-router-dom'
import { Suspense, lazy } from 'react'
import { useCustomerSettings } from 'hooks/useCustomerSettings'
import { DashboardPreviewBanner } from 'components/DashboardPreviewBanner'
import { useSettingsStore } from 'hooks/store/useSettingsStore'
import { setSetting } from 'services/SettingsProvider'
import { useUser } from 'hooks'

const Admin = lazy(() => import('../../routes/admin/Admin'))
const Customers = lazy(() => import('routes/customers/Customers'))
const Scripts = lazy(() => import('routes/operator/backendScripts'))
const Dashboard = lazy(() => import('routes/dashboard/Dashboard'))
const Dashboard2 = lazy(() => import('routes/dashboard/Dashboard2'))
const Foodwaste = lazy(() => import('routes/foodwaste/Foodwaste'))
const Orders = lazy(() => import('routes/orders'))
const Forecasts = lazy(() => import('routes/forecasts/Forecasts'))
const Login = lazy(() => import('routes/login/Login'))
const Register = lazy(() => import('routes/register/Register'))
const ForgotPassword = lazy(() => import('routes/forgotPassword/ForgotPassword'))
const SetPassword = lazy(() => import('routes/setPassword/SetPassword'))
const Todo = lazy(() => import('routes/todo/Todo'))
const Settings = lazy(() => import('routes/settings/Settings'))
const Development = lazy(() => import('routes/development/Development'))
const Report = lazy(() => import('routes/report/Report'))

export const ADMIN_TAB_ROUTES = {
  manageUsers: ADMIN_USERS_ROUTE,
  'sales-locations': ADMIN_LOCATIONS_ROUTE,
  items: ADMIN_ITEMS_ROUTE,
  foodwaste: ADMIN_FOODWASTE_ROUTE,
  'order-configs': ADMIN_ORDERCONFIGS_ROUTE,
  'todo-configs': ADMIN_TODOCONFIGS_ROUTE,
  'controllable-client': ADMIN_CONTROLLABLE_CLIENT_ROUTE,
  'recipe-lines': ADMIN_RECIPELINES_ROUTE,
  events: ADMIN_EVENTS_ROUTE,
  import: ADMIN_IMPORT_ROUTE,
  sftp: ADMIN_SFTP_ROUTE
}

export const SETTINGS_TAB_ROUTES = {
  general: SETTINGS_GENERAL_ROUTE,
  security: SETTINGS_SECURITY_ROUTE,
  user: SETTINGS_USER_ROUTE,
  subscriptions: SETTINGS_SUBSCRIPTIONS_ROUTE,
  about: SETTINGS_ABOUT_ROUTE
}

const DashboardDecider = () => {
  const { user } = useUser()
  const customerSettings = useCustomerSettings()
  const globalSettings = useSettingsStore(state => state.globalSettings)

  const onPurge = () => {
    setSetting({ dashboardPreviewDontAsk: true }, true)
  }

  const canUseNewDashboard = customerSettings && customerSettings.dashboardPreview === true && user && user.admin_settings.dashboardPreview

  return (
    <>
      {canUseNewDashboard && !globalSettings.dashboardPreview && !globalSettings.dashboardPreviewDontAsk && <DashboardPreviewBanner onPurge={onPurge} />}
      {canUseNewDashboard && globalSettings.dashboardPreview === true ? <Dashboard2 /> : <Dashboard />}
    </>
  )
}

const adminTabKeys = Object.keys(ADMIN_TAB_ROUTES)
const settingsTabKeys = Object.keys(SETTINGS_TAB_ROUTES)

const Router = () => (
  <Suspense fallback={<div />}>
    <Routes>
      <Route path={ROOT_ROUTE} element={<DashboardDecider />} />
      <Route path={`${DASHBOARD_ROUTE}/:name?`} element={<DashboardDecider />} />
      <Route path={FORECASTS_ROUTE} element={<Forecasts />} />
      <Route path={FOODWASTE_ROUTE} element={<Foodwaste />} />
      <Route path={ORDERS_ROUTE} element={<Orders />} />
      <Route path={TODO_ROUTE} element={<Todo />} />
      <Route path={DEVELOPMENT_ROUTE} element={<Development />} />

      {adminTabKeys.map((key) => <Route key={key} path={ADMIN_TAB_ROUTES[key]} element={<Admin tab={key} />} />)}
      {settingsTabKeys.map((key) => <Route key={key} path={SETTINGS_TAB_ROUTES[key]} element={<Settings tab={key} />} />)}

      <Route path={CUSTOMERS_ROUTE} element={<Customers />} />
      <Route path={SCRIPTS_ROUTE} element={<Scripts />} />
      <Route path={USERS_ROUTE} element={<Admin />} />

      <Route path={LOGIN_ROUTE} element={<Login />} />
      <Route path={REGISTER_ROUTE} element={<Register />} />
      <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
      <Route path={SET_PASSWORD_ROUTE} element={<SetPassword />} />

      <Route path={REPORT_ROUTE} element={<Report />} />
    </Routes>
  </Suspense>
)

export default Router
