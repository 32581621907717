import { Button } from 'components/Button'
import { ConfirmationDialog } from 'components/ConfirmationDialog'
import { TextFieldDialog } from 'components/Dialogs/TextFieldDialog'
import globalMessages from 'components/globalMessages'
import labelMessages from 'components/labelMessages'
import titleMessages from 'components/titleMessages'
import { Text } from 'components/Primitives'
import { find, kebabCase } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import s from '../GridComponents.module.scss'
import messages from '../messages'
import { DropdownMenu } from 'components/DropdownMenu'
import { TextSelect } from 'components/TextSelect'
import { useModalStore } from 'hooks/store/useModalStore'
import { useGridSettingsHandler } from '../hooks/useGridSettingsHandler'

const getDensityIcon = (density) => {
  switch (density) {
    case 'regular':
      return 'DensityMiddle'
    case 'condensed':
      return 'DensityDefault'
    case 'relaxed':
      return 'DensityHuge'
  }
}

export const ViewToolPanel = ({ api, standalone, standaloneSettings, setStandaloneSettings, settingsKey, id }) => {
  const intl = useIntl()
  const { settings, set } = useGridSettingsHandler({ id, settingsKey, standalone, standaloneSettings, setStandaloneSettings })

  const saveAsDialog = `${id}_saveAsDialog`
  const deletionConfirmation = `${id}_deletionConfirmation`
  const saveAsDialogModal = useModalStore(state => state.modals[saveAsDialog])
  const deletionConfirmationModal = useModalStore(state => state.modals[deletionConfirmation])
  const show = useModalStore(state => state.show)
  const hide = useModalStore(state => state.hide)

  // when sizing to fit, we need to wait for the tool panel to close
  const handleSizeToFit = () => {
    api.closeToolPanel()
    window.setTimeout(() => { api.sizeColumnsToFit() }, 200)
  }

  const DefaultID = 'default'

  const options = !settings.colStates || settings.colStates.length === 0
    ? [{ value: DefaultID, label: intl.formatMessage(labelMessages.defaultView) }]
    : settings.colStates.map((state) => ({
      value: state.id,
      label: state.id === DefaultID
        ? intl.formatMessage(labelMessages.defaultView)
        : state.name || intl.formatMessage(labelMessages.untitled)
    }))

  const currentViewName = useMemo(() => {
    if (!settings.colStates) {
      return intl.formatMessage(labelMessages.defaultView)
    }
    const view = find(settings.colStates, { id: settings.colView })
    return view ? view.name : intl.formatMessage(labelMessages.defaultView)
  }, [settings.colStates, settings.colView])

  const handleViewChange = (viewId) => {
    const view = settings.colStates ? find(settings.colStates, { id: viewId }) : null
    if (!view) { return }
    set({
      colView: view.id
    })
    api.applyColumnState({
      state: view.state,
      applyOrder: true
    })
  }

  const handleSaveAsNew = (name) => {
    const id = kebabCase(name)

    const newColStates = settings.colStates ? [...settings.colStates] : []

    // when we have no col states, this means the default view was never edited and therefore not saved.
    // add them to the list of saved views
    if (newColStates.length === 0) {
      newColStates.push({
        id: DefaultID,
        name: intl.formatMessage(labelMessages.defaultView),
        state: api.getColumnState()
      })
    }
    const index = newColStates.findIndex((item) => item.id === id)
    if (index === -1) {
      newColStates.push({
        id,
        name,
        state: api.getColumnState()
      })
    } else {
      newColStates[index] = {
        id,
        name,
        state: api.getColumnState()
      }
    }

    set({
      colView: id,
      colStates: newColStates
    })
    hide(saveAsDialog)
  }

  const handleViewDeletion = () => {
    hide(deletionConfirmation)
    if (settings.colView === DefaultID) return
    const newColStates = settings.colStates.filter((item) => item.id !== settings.colView)

    handleViewChange(DefaultID)
    set({
      colView: DefaultID,
      colStates: newColStates
    })
  }

  return (
    <div data-role='toolbar-view' className={s.toolbarPanel}>
      <div className='flex items-start justify-between'>
        <Text type='table' size='m' className='mb-4'>
          <FormattedMessage tagName='p' {...titleMessages.view} />
        </Text>
        <DropdownMenu
          menuSections={[{
            key: 'density',
            menuItems: [
              {
                key: 'condensed',
                label: intl.formatMessage(labelMessages.densityCondensed),
                icon: 'DensityDefault',
                active: settings.colDensity === 'condensed',
                onClick: () => set({ colDensity: 'condensed' })
              },
              {
                key: 'regular',
                label: intl.formatMessage(labelMessages.densityRegular),
                icon: 'DensityMiddle',
                active: settings.colDensity === 'regular',
                onClick: () => set({ colDensity: 'regular' })
              },
              {
                key: 'relaxed',
                label: intl.formatMessage(labelMessages.densityRelaxed),
                icon: 'DensityHuge',
                active: settings.colDensity === 'relaxed',
                onClick: () => set({ colDensity: 'relaxed' })
              }
            ]
          }]}
          align='end'
          name='drpdwn-density'
          checkmark
          smallWidth
        >
          <Button className='-mt-1' size='s' type='primary' pure icon={getDensityIcon(settings.colDensity)} />
        </DropdownMenu>
      </div>
      <p className='mb-4'>
        <Text color='gray' type='body' size='xs'>
          <FormattedMessage {...messages.viewPanelDescription} />
        </Text>
      </p>
      <TextSelect
        data-name='change_view'
        value={settings.colView || DefaultID}
        onChange={handleViewChange}
        options={options}
        creatable={false}
        allowClear={false}
        required
      />
      <div className={s.actionButtons}>
        <Button data-action='delete-view' type='tertiary' disabled={!(settings.colView && settings.colView !== DefaultID)} size='s' onClick={() => show(deletionConfirmation)}>
          {intl.formatMessage(messages.deleteCurrentView)}
        </Button>
        <Button data-action='create-view' fullWidth className='mt-4 mb-6' type='secondary' size='m' onClick={() => show(saveAsDialog)}>
          {intl.formatMessage(messages.createNewView)}
        </Button>
        <Button data-action='reset-columns' type='tertiary' size='s' onClick={() => { api.resetColumnState() }}>
          {intl.formatMessage(messages.resetColumns)}
        </Button>
        <Button data-action='fit-columns' type='tertiary' size='s' onClick={handleSizeToFit}>
          {intl.formatMessage(messages.fitColumns)}
        </Button>
        <Button data-action='autosize-columns' type='tertiary' size='s' onClick={() => { api.autoSizeAllColumns() }}>
          {intl.formatMessage(messages.autosizeAllColumns)}
        </Button>
      </div>
      <TextFieldDialog
        title={intl.formatMessage(messages.createNewView)}
        onCancel={() => hide(saveAsDialog)}
        visible={saveAsDialogModal}
        onSubmit={handleSaveAsNew}
      >
        <FormattedMessage tagName='p' {...messages.saveAsNewViewDescription1} />
        <FormattedMessage tagName='p' {...messages.saveAsNewViewDescription2} />
      </TextFieldDialog>
      <ConfirmationDialog
        title={intl.formatMessage(messages.deleteCurrentView)}
        onCancel={() => hide(deletionConfirmation)}
        visible={deletionConfirmationModal}
        onConfirm={handleViewDeletion}
      >
        <FormattedMessage
          {...messages.deleteCurrentViewDescription}
          tagName='p'
          values={{ name: currentViewName, b: chunks => <b>{chunks}</b> }}
        />
        <br />
        <FormattedMessage {...globalMessages.noUndo} tagName='p' />
      </ConfirmationDialog>
    </div>
  )
}
