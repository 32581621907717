import {
  CaretSort,
  Launch,
  Add,
  Subtract,
  Information,
  Export,
  Language,
  RainDrop,
  Calendar,
  Settings,
  LogoLinkedin,
  Screen,
  TrashCan,
  Close,
  Locked,
  Save,
  Email,
  Filter,
  Edit,
  Time,
  WarningAltFilled,
  Password,
  Calculator,
  CalculatorCheck,
  ChartPie,
  Tablet,
  Train,
  Restaurant,
  Logout,
  Chat,
  Recommend,
  Search,
  Idea,
  Checkmark,
  IbmSecurity,
  Warning,
  Move,
  SendAlt,
  Activity,
  FaceWink,
  FaceCool,
  User,
  Analytics,
  ChartColumn,
  Draggable,
  Error,
  UserAvatar,
  Catalog,
  OverflowMenuVertical,
  Calibrate,
  Events,
  Event,
  Industry,
  AlarmAdd,
  Timer,
  Alarm,
  ReportData,
  Table,
  Thumbnail_2,
  EarthFilled,
  RequestQuote,
  Touch_1,
  WatsonHealthRotate_360,
  Data_1,
  Reset,
  Network_4,
  Network_3,
  ScriptReference,
  ShoppingBag,
  Copy,
  CopyFile,
  Network_1,
  UserAdmin,
  Wallet,
  Repeat,
  InProgress,
  CloseFilled,
  View,
  RecentlyViewed,
  CaretUp,
  CaretDown,
  CaretLeft,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  ArrowLeft,
  ArrowRight,
  ArrowUpRight,
  ArrowDownRight,
  Play,
  PlayFilledAlt,
  CheckmarkFilled,
  Renew,
  ZoomIn,
  DeliveryAdd,
  Download,
  Upload,
  Attachment,
  WarningFilled,
  CheckmarkOutline,
  Misuse,
  MisuseOutline,
  InformationFilled,
  Chemistry,
  ChevronSort,
  Unlink,
  Wikis,
  ChartBar,
  ChartRing,
  ChartStacked,
  DocumentView,
  DataViewAlt,
  Maximize,
  Minimize,
  Parameter,
  Terminal,
  Unknown,
  ApplicationMobile,
  Strawberry,
  Badge,
  Star,
  StarFilled,
  Share,
  UserMultiple,
  CodeBlock,
  SummaryKpi,
  MeterAlt,
  Help,
  Compare,
  IbmWatsonxAssistant,
  IbmWatsonKnowledgeStudio,
  GuiManagement,
  SortAscending,
  SortDescending,
  ExpandCategories,
  Sigma,
  RowExpand,
  SortRemove,

  // Editor
  ListBulleted,
  ListNumbered,
  TextAlignCenter,
  TextAlignLeft,
  TextAlignRight,
  TextAlignJustify,
  Label,
  Link,
  TextBold,
  TextItalic,
  TextUnderline,
  TextStrikethrough,
  TextIndentLess,
  TextIndentMore,
  TextLongParagraph,
  Quotes,
  PageBreak,
  Redo,
  Undo,
  TextCreation,
  OpenPanelLeft
} from '@carbon/icons-react'
import { ReactComponent as AddProduction } from 'static/icons/addProduction.svg'
import { ReactComponent as Automatic } from 'static/icons/automatic.svg'
import { ReactComponent as Book } from 'static/icons/book.svg'
import { ReactComponent as BurgerMenu } from 'static/icons/burgermenu.svg'
import { ReactComponent as DensityDefault } from 'static/icons/density-default.svg'
import { ReactComponent as DensityHuge } from 'static/icons/density-huge.svg'
import { ReactComponent as DensityMiddle } from 'static/icons/density-middle.svg'
import { ReactComponent as Dog } from 'static/icons/dog.svg'
import { ReactComponent as FillingRate } from 'static/icons/fillingrate.svg'
import { ReactComponent as FilterBars } from 'static/icons/filterBars.svg'
import { ReactComponent as Goods } from 'static/icons/goods.svg'
import { ReactComponent as Import } from 'static/icons/import.svg'
import { ReactComponent as IndicatorTable } from 'static/icons/indicatorTable.svg'
import { ReactComponent as Inventory } from 'static/icons/inventory.svg'
import { ReactComponent as Location } from 'static/icons/location.svg'
import { ReactComponent as Logo } from 'static/icons/logo.svg'
import { ReactComponent as Measurement } from 'static/icons/measurement.svg'
import { ReactComponent as Meat } from 'static/icons/meat.svg'
import { ReactComponent as NoData } from 'static/noData.svg'
import { ReactComponent as PieChart } from 'static/icons/piechart.svg'
import { ReactComponent as Scale } from 'static/icons/scale.svg'
import { ReactComponent as Speedometer } from 'static/icons/speedometer.svg'
import { ReactComponent as Tag } from 'static/icons/tag.svg'
import { ReactComponent as TimerDone } from 'static/icons/timerDone.svg'
import { ReactComponent as Tracking } from 'static/icons/tracking.svg'
import { ReactComponent as Vegan } from 'static/icons/vegan.svg'
import { ReactComponent as ChevronLeftDouble } from 'static/icons/chevron-left-double.svg'
import { ReactComponent as ChevronRightDouble } from 'static/icons/chevron-right-double.svg'
import { ReactComponent as Vegetarian } from 'static/icons/vegetarian.svg'
import { ReactComponent as BlowingSnow } from 'static/icons/weather/blowing-snow.svg'
import { ReactComponent as Cloudy } from 'static/icons/weather/cloudy.svg'
import { ReactComponent as Fog } from 'static/icons/weather/fog.svg'
import { ReactComponent as Lighting } from 'static/icons/weather/lighting.svg'
import { ReactComponent as Overcast } from 'static/icons/weather/overcast.svg'
import { ReactComponent as PartlyCloudy } from 'static/icons/weather/partly-cloudy.svg'
import { ReactComponent as RainyHeavy } from 'static/icons/weather/rainy-heavy.svg'
import { ReactComponent as RainyLow } from 'static/icons/weather/rainy-low.svg'
import { ReactComponent as RainyModerate } from 'static/icons/weather/rainy-moderate.svg'
import { ReactComponent as SleetHeavy } from 'static/icons/weather/sleet-heavy.svg'
import { ReactComponent as SleetLow } from 'static/icons/weather/sleet-low.svg'
import { ReactComponent as SleetModerate } from 'static/icons/weather/sleet-moderate.svg'
import { ReactComponent as SnowHeavy } from 'static/icons/weather/snow-heavy.svg'
import { ReactComponent as SnowLow } from 'static/icons/weather/snow-low.svg'
import { ReactComponent as SnowModerate } from 'static/icons/weather/snow-moderate.svg'
import { ReactComponent as Sunny } from 'static/icons/weather/sunny.svg'
import { ReactComponent as ThunderRain } from 'static/icons/weather/thunder-rain.svg'
import { ReactComponent as ThunderSnow } from 'static/icons/weather/thunder-snow.svg'
import { ReactComponent as MiniCard } from 'static/icons/miniCard.svg'
import { ReactComponent as TypeH1 } from 'static/editor/type-h1.svg'
import { ReactComponent as TypeH2 } from 'static/editor/type-h2.svg'

import classNames from 'classnames'
import s from './Icon.module.scss'

const CARBON_ICONS = {
  CaretSort,
  Launch,
  Add,
  Subtract,
  Information,
  Export,
  Language,
  RainDrop,
  Calendar,
  Settings,
  LogoLinkedin,
  Screen,
  TrashCan,
  Close,
  Locked,
  Save,
  Email,
  Filter,
  Edit,
  Time,
  WarningAltFilled,
  Password,
  Calculator,
  CalculatorCheck,
  ChartPie,
  Tablet,
  Train,
  Restaurant,
  Logout,
  Chat,
  Recommend,
  Search,
  Idea,
  Checkmark,
  IbmSecurity,
  Warning,
  Move,
  SendAlt,
  Activity,
  FaceWink,
  FaceCool,
  User,
  Analytics,
  ChartColumn,
  Draggable,
  Error,
  UserAvatar,
  Catalog,
  OverflowMenuVertical,
  Calibrate,
  Events,
  Event,
  Industry,
  AlarmAdd,
  Timer,
  Alarm,
  ReportData,
  Table,
  Thumbnail_2,
  EarthFilled,
  RequestQuote,
  Touch_1,
  WatsonHealthRotate_360,
  Data_1,
  Reset,
  Network_4,
  Network_3,
  ScriptReference,
  ShoppingBag,
  Copy,
  CopyFile,
  Network_1,
  UserAdmin,
  Wallet,
  Repeat,
  InProgress,
  CloseFilled,
  View,
  RecentlyViewed,
  CaretUp,
  CaretDown,
  CaretLeft,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  ArrowLeft,
  ArrowRight,
  ArrowUpRight,
  ArrowDownRight,
  Play,
  PlayFilledAlt,
  CheckmarkFilled,
  Renew,
  ZoomIn,
  DeliveryAdd,
  Download,
  Upload,
  Attachment,
  WarningFilled,
  CheckmarkOutline,
  Misuse,
  MisuseOutline,
  InformationFilled,
  Chemistry,
  ChevronSort,
  Wikis,
  ChartBar,
  ChartRing,
  ChartStacked,
  DocumentView,
  DataViewAlt,
  Maximize,
  Minimize,
  Parameter,
  Terminal,
  Unknown,
  ApplicationMobile,
  Strawberry,
  Badge,
  Star,
  StarFilled,
  Share,
  UserMultiple,
  CodeBlock,
  Help,
  SummaryKpi,
  Compare,
  MeterAlt,
  // Editor
  ListBulleted,
  ListNumbered,
  TextAlignCenter,
  TextAlignLeft,
  TextAlignRight,
  TextAlignJustify,
  Label,
  Link,
  TextBold,
  TextItalic,
  TextUnderline,
  TextStrikethrough,
  TextIndentLess,
  TextIndentMore,
  TextLongParagraph,
  Quotes,
  PageBreak,
  Redo,
  Undo,
  TextCreation,
  IbmWatsonxAssistant,
  IbmWatsonKnowledgeStudio,
  GuiManagement,
  SortAscending,
  SortDescending,
  ExpandCategories,
  Sigma,
  RowExpand,
  OpenPanelLeft,
  SortRemove
}

const CUSTOM_ICONS = {
  AddProduction,
  Automatic,
  Book,
  BurgerMenu,
  DensityDefault,
  DensityHuge,
  DensityMiddle,
  Dog,
  FillingRate,
  FilterBars,
  Goods,
  Import,
  IndicatorTable,
  Inventory,
  Location,
  Logo,
  Measurement,
  Meat,
  NoData,
  PieChart,
  Scale,
  Speedometer,
  Tag,
  TimerDone,
  Tracking,
  Vegan,
  Vegetarian,
  BlowingSnow,
  Cloudy,
  Fog,
  Lighting,
  Overcast,
  PartlyCloudy,
  RainyHeavy,
  RainyLow,
  RainyModerate,
  SleetHeavy,
  SleetLow,
  SleetModerate,
  SnowHeavy,
  SnowLow,
  SnowModerate,
  Sunny,
  ThunderRain,
  ThunderSnow,
  ChevronLeftDouble,
  ChevronRightDouble,
  Unlink,
  MiniCard,
  TypeH1,
  TypeH2
}

export const CARBON_ICON_KEYS = Object.keys(CARBON_ICONS)
export const CUSTOM_ICON_KEYS = Object.keys(CUSTOM_ICONS)
const sizes = ['xxs', 'xs', 's', 'm', 'm2', 'l', 'xl', 'xxl', 'xxxl', 'unset']
const basicColors = ['blue', 'gray', 'danger', 'success', 'warning']

/**
 * @typedef {red | green | blue} Name
 */

/**
 * @param {object} props
 * @param {Name} props.name
 */
export const Icon = ({ name, color, size = 'm', clickable, className, omitFill, flexWrapped, ...props }) => {
  let Component

  if (CARBON_ICON_KEYS.includes(name)) {
    Component = CARBON_ICONS[name]
  } else if (CUSTOM_ICON_KEYS.includes(name)) {
    Component = CUSTOM_ICONS[name]
  } else {
    Component = Unknown
  }

  const realSize = sizes.includes(size) ? size : 'm'

  const c = (
    <Component
      className={classNames(!omitFill && 'fill-current', s[realSize], clickable && 'hover:text-primary-lighter', basicColors.includes(color) && {
        'text-primary-lighter': color === 'blue',
        'text-feedback-error': color === 'danger',
        'text-feedback-success': color === 'success',
        'text-gray-light': color === 'gray',
        'text-feedback-warning': color === 'warning'
      }, !basicColors.includes(color) && color, className)}
      data-cy='icon'
      data-icon-name={name}
      {...props}
    />
  )

  if (flexWrapped) return <div className={classNames('icon-flex-wrapper', s.flexWrapper)}>{c}</div>
  return c
}
