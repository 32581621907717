import { Button } from 'components/Button'
import s from './CellRenderer.module.scss'
import { DropdownMenu } from 'components/DropdownMenu'
import { Switch } from 'components/Primitives'

const renderButton = (button, index, context, density) => {
  const { data } = context
  const hidden = button.hidden != null
    ? typeof button.hidden === 'function' ? button.hidden(data) : button.hidden
    : undefined
  if (hidden) return null

  const disabled = button.disabled != null
    ? typeof button.disabled === 'function' ? button.disabled(data) : button.disabled
    : undefined

  let btnComponent

  switch (button.componentType) {
    case 'button':
    default:
      btnComponent = (
        <Button
          key={button.key || index}
          data-action={button.key}
          icon={button.icon}
          size={density === 'condensed' ? 'xs' : 's'}
          whiteHoverBg
          pure
          type='secondary'
          onClick={button.onClick ? () => button.onClick(context) : undefined}
          title={button.title}
          disabled={disabled}
        />
      )
      break
    case 'switch':
      btnComponent = (
        <Switch
          key={button.key || index}
          small
          checked={data[button.checkedKey]}
          onChange={checked => button.onChange(context, checked)}
          disabled={disabled}
        />
      )
      break
  }
  if (button.menuSections) {
    // we render a dropdown menu
    return (
      <DropdownMenu
        key={button.key || index}
        menuSections={button.menuSections}
        align='end'
        name={`drpdwn-${button.key}`}
        smallWidth
        context={context}
      >
        {btnComponent}
      </DropdownMenu>
    )
  }
  return btnComponent
}

export const ButtonsRenderer = ({ api, node, data, colDef }) => {
  return (
    <div className={s.buttons}>
      {colDef.buttons.map((b, index) => {
        const context = { api, node, data }
        const button = typeof b === 'function' ? b(context) : b
        return renderButton(button, index, context, colDef.density)
      })}
    </div>
  )
}
